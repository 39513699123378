<template>
    <div :title="selectedName" class="d-none">
        <label>Measurement</label>
            <div :class="[multipleOptions ? 'select' : 'one_option select']">
            <select v-model="selected">
                <option v-for="option in metricUnits" v-bind:value="option.id">{{option.name}}</option>
            </select>
            <div class="select-styled-container"><div class="select-styled">{{selectedName}}</div></div>
        </div>
    </div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex';
import URL from '@/util/url';
export default {
     data : function () {
        return {

        }
    },
    mounted(){
        //this.getData();
    },
    computed : {
        ...mapGetters('tvd/',[
            'metricUnits',
            'metricUnit'
        ]),
        multipleOptions : function(){
            if(!this.metricUnits){
                return;
            }

            if(! Array.isArray(this.metricUnits)){
                return;
            }

            if(this.metricUnits.length <= 1){
                return;
            }

            return true;

        },
        selectedName :function() {

            if(!this.metricUnits){
                return;
            }


            let match = _.find(this.metricUnits, { id : this.selected });

            if(!match){
              return _.get(_.first(this.metricUnits), "name");
            }

            return match.name;
        },
        selected : {
            get () {
                return this.metricUnit.id;
            },
            set (value) {
                this.setMetricUnitState(_.find(this.metricUnits, { id : value }));
            }
        }
    },
    methods : {
        ...mapActions('tvd/',[
                'setMetricUnitState'
            ]
        )
    },
    watch : {
        metricUnits : function(){

            let match = _.find(this.metricUnits, { id : URL.hash.unit });
            if(match){
                this.setMetricUnitState(match);
                return;
            }
            //if(_.isEmpty(this.metricUnits)){
            //    return;
           // }
            this.setMetricUnitState( _.first(this.metricUnits) );
        }
    }
}
</script>