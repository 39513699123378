<template>
	<div class="tvd" id="nav-tvd" role="tabpanel" aria-labelledby="nav-total-value-delivered-tab" tabindex="0">
		<backgrounds v-if="!widget"></backgrounds>
		<div class="content">
			<div class="row h-100" v-if="!widget">
				<div class="col-md-8 col-xl-9 order-md-2">
					<section class="count">
						<div class="border_lg d-flex align-items-center">
							<div class="w-100">
								<h4>Our Total Value Delivered Is</h4>
								<div id="numberContainer">
									<numbers></numbers>
								</div>
								<div class="inputs row justify-content-center align-items-centerx less_gutter">
									<metric-select></metric-select>
									<div class="col-auto d-flex align-items-center time">
										<period-select></period-select>
									</div>
								</div>
								<div class="dynamic_factoid">
									<h5>Value documented and completed with customers</h5>
									<h3>globally in 2023</h3>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div class="d-none d-md-block col-md-4 col-xl-3">
					<aside>
						<div class="aside_border d-flex align-items-center">
							<div>
								<h2>What is Total Value Delivered?</h2>
								<p>Total Value Delivered is the monetization of the combined benefits of our solutions and services to help customers quantify their return on investment.</p>
							</div>
						</div>
					</aside>
				</div>
			</div>
			<section class="h-100 count" v-else-if="widget">
				<div class="border_lg">
					<div class="w-100">
						<h4>Our Total Value Delivered Is</h4>
						<div id="numberContainer">
							<numbers></numbers>
						</div>
						<h2 class="text-lowercase mb-0">
							{{ selectedPeriod }}
						</h2>
						<div class="dynamic_factoid">
							<h5>Value documented and completed with customers</h5>
							<h3>globally in 2020</h3>
						</div>
					</div>
				</div>
				<div class="d-none">
					<metric-select></metric-select>
					<period-select></period-select>
				</div>
				<a :href="fullScreenLink" class="widget_fullscreen_link" target="_blank" aria-label="Fullscreen Mode">Fullscreen</a>
			</section>
		</div>
	</div>
</template>

<script>
import numbers from "@/components/tvd/Numbers.vue";
import metricSelect from "@/components/tvd/MetricSelect.vue";
import periodSelect from "@/components/tvd/PeriodSelect.vue";
import backgrounds from "@/components/Backgrounds.vue";
import { mapGetters } from 'vuex';
import URL from "@/util/url.js";
export default {
	computed : {
		...mapGetters('tvd/',[
		    'periods',
		    'period',
		]),
		...mapGetters([
			'widget',
		]),
		selectedPeriod :function() {

		    if(!this.periods){
		        return;
		    }

		    let match = _.first(_.filter(this.periods, { id :this.selected }));

		    if(!match){
				return _.get(_.first(this.periods), "name");
		    }

		    return match.name;
		},
		currentRouteModel : function() {
		    if(!this.$route){
		        return;
		    }

		    if(!this.$route.hasOwnProperty('meta')){
		        return;
		    }

		    if(!this.$route.meta.hasOwnProperty('vuexModel')){
		        //console.error('this route is missing a vuexModel in routes/index')
		        return;
		    }
		    return `${this.$route.meta.vuexModel}`;
		},
		fullScreenLink: function() {
		    let url = window.location.protocol + "//" + window.location.host + ((window.location.pathname == '/') ? '' : window.location.pathname );
		    let hash = URL.drop(window.location.hash, 'type');

		    return `${url}/#${hash}`;
		}
	},
	components : {
		numbers,
		metricSelect,
		periodSelect,
		backgrounds
	}
}
</script>